html {
  background-color: white;
  padding: 0 5vw; }

body {
  width: 100%;
  max-width: 800px;
  margin: 10vh auto;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif; }
  body.has-soft-keyboard {
    margin-bottom: 0; }
  @media (orientation: portrait) {
    body {
      font-size: 90%; } }
  body.assume-mobile {
    font-size: 90%; }

.npm_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  box-shadow: rgba(255, 255, 255, 0.6) 0 0 5px; }
  .npm_header .npm_header_content {
    width: 100%;
    max-width: 800px; }
  .npm_header .npm_header_progressbar {
    height: 5px;
    background-color: #eee;
    margin-bottom: 1rem;
    border-radius: 2.5px; }
    .completed .npm_header .npm_header_progressbar {
      background: none; }
    @media (orientation: portrait) {
      .npm_header .npm_header_progressbar {
        margin-bottom: 0.3rem; } }
    .assume-mobile .npm_header .npm_header_progressbar {
      margin-bottom: 0.3rem; }
    .npm_header .npm_header_progressbar .npm_header_progressbar_indicator {
      height: 100%;
      border-radius: 2.5px;
      background-color: black;
      transition: width 1s; }
      .completed .npm_header .npm_header_progressbar .npm_header_progressbar_indicator {
        background: none; }
  .npm_header .npm_header_text {
    display: flex;
    justify-content: space-between; }
  .npm_header h1 {
    font-size: 2rem;
    margin: 0; }
    @media (orientation: portrait) {
      .npm_header h1 {
        font-size: 1rem; } }
    .assume-mobile .npm_header h1 {
      font-size: 1rem; }

.sv_progress_bar {
  display: none; }

.sv_nav {
  display: none; }

h5.sv_q_title {
  font-size: 150%;
  margin-bottom: 0.5rem; }
  @media (orientation: portrait) {
    h5.sv_q_title {
      font-size: 120%;
      line-height: 1.1; } }

button, input[type="button"] {
  background-color: white;
  border: 2px solid black;
  color: black;
  padding: 0.5em 1em; }

input[type="text"], input[type="number"], input[type="tel"] {
  background-color: white;
  border: none;
  border-bottom: 2px solid black;
  color: black;
  padding: 0.5em 0.2em;
  font-size: 120%;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0; }

input[type="text"] {
  width: 100%; }

input[type="radio"], input[type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 1em;
  height: 1em;
  border: 2px solid black;
  margin: 0 0.3em 3px 0 !important;
  vertical-align: middle; }
  input[type="radio"][type="radio"], input[type="checkbox"][type="radio"] {
    border-radius: 50%; }
  input[type="radio"]:checked, input[type="checkbox"]:checked {
    background-color: black; }

.sv_row {
  color: gray; }
  .sv_row:last-child {
    font-size: 150%;
    color: black;
    margin: 10vh 0 40vh; }
    .has-soft-keyboard .sv_row:last-child {
      font-size: 120%; }
    @media (orientation: portrait) {
      .sv_row:last-child {
        margin-bottom: 30vh; } }
    .sv_row:last-child:first-child, .completed .sv_row:last-child {
      margin-top: 35vh; }
    .start .sv_row:last-child {
      margin-top: 20vh; }
    .start .sv_row:last-child, .completed .sv_row:last-child {
      margin-bottom: 5vh; }
    .assume-mobile .sv_row:last-child.has-text-input, .sv_row:last-child.long-question {
      margin-bottom: 20px; }
  .sv_row h3 {
    margin-bottom: 0.2em; }
  .sv_row .normal {
    font-size: 80%; }
  .sv_row .small {
    font-size: 65%;
    line-height: 120%;
    margin: 1em 0; }
    .sv_row .small p {
      margin-top: 0.7em;
      margin-bottom: 0; }

.sv_q_description {
  font-size: 80%;
  margin-bottom: 1rem; }

.answer-with-unit {
  display: flex;
  align-items: baseline;
  max-width: 100%; }

.unit {
  font-weight: bold;
  margin-left: 0.5em;
  font-size: 120%; }

.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

