$WIDTH: 800px;

html {
  background-color: white;
  padding: 0 5vw;
}

body {
  width: 100%;
  max-width: $WIDTH;
  margin: 10vh auto;
  &.has-soft-keyboard {
    margin-bottom: 0;
  }
  padding: 0;

  font-family: Helvetica, Arial, sans-serif;

  @media (orientation: portrait) {
    font-size: 90%;
  }
  &.assume-mobile {
    font-size: 90%;
  }
}

.npm_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;

  display: flex;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1px);
  box-shadow: rgba(255, 255, 255, 0.6) 0 0 5px;

  .npm_header_content {
    width: 100%;
    max-width: $WIDTH;
  }
  .npm_header_progressbar {
    height: 5px;
    background-color: #eee;
    .completed & {
      background: none;
    }

    margin-bottom: 1rem;
    @media (orientation: portrait) {
      margin-bottom: 0.3rem;
    }
    .assume-mobile & {
      margin-bottom: 0.3rem;
    }

    border-radius: 2.5px;

    .npm_header_progressbar_indicator {
      height: 100%;
      border-radius: 2.5px;
      background-color: black;
      .completed & {
        background: none;
      }

      transition: width 1s;
    }
  }
  .npm_header_text {
    display: flex;
    justify-content: space-between;
  }
  h1 {
    font-size: 2rem;
    @media (orientation: portrait) {
      font-size: 1rem;
    }
    .assume-mobile & {
      font-size: 1rem;
    }
    margin: 0;
  }
}

.sv_progress_bar {
  display: none;
}

.sv_nav {
  display: none;
  //margin-top: 3rem;
  //
  //display: flex;
  //justify-content: flex-end;
}

h5.sv_q_title {
  font-size: 150%;
  margin-bottom: 0.5rem;

  @media (orientation: portrait) {
    font-size: 120%;
    line-height: 1.1;
  }
}

button, input[type="button"] {
  background-color: white;
  border: 2px solid black;
  color: black;
  padding: 0.5em 1em;
}

input[type="text"], input[type="number"], input[type="tel"] {
  background-color: white;
  border: none;
  border-bottom: 2px solid black;
  color: black;
  padding: 0.5em 0.2em;
  font-size: 120%;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0; // flex box needs this to enable shrinking
}

input[type="text"] {
  width: 100%;
}

input[type="radio"], input[type="checkbox"] {
  appearance: none;
  width: 1em;
  height: 1em;
  border: 2px solid black;
  &[type="radio"] {
    border-radius: 50%;
  }

  margin: 0 0.3em 3px 0 !important; // surveyjs sets margin
  vertical-align: middle;

  &:checked {
    background-color: black;
  }
}

.sv_row {
  color: gray;

  &:last-child {
    font-size: 150%;
    .has-soft-keyboard & {
      font-size: 120%;
    }

    color: black;
    margin: 10vh 0 40vh;
    @media (orientation: portrait) {
      margin-bottom: 30vh;
    }
    &:first-child, .completed & {
      margin-top: 35vh;
    }
    .start & {
      margin-top: 20vh;
    }
    .start &, .completed & {
      margin-bottom: 5vh;
    }
    .assume-mobile &.has-text-input, &.long-question {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 0.2em;
  }

  .normal {
    font-size: 80%;
  }

  .small {
    font-size: 65%;
    line-height: 120%;
    margin: 1em 0;

    p {
      margin-top: 0.7em;
      margin-bottom: 0;
    }
  }
}

.sv_q_description {
  font-size: 80%;
  margin-bottom: 1rem;
}

.answer-with-unit {
  display: flex;
  //justify-content: stretch;
  align-items: baseline;
  max-width: 100%;
}
.unit {
  font-weight: bold;
  margin-left: 0.5em;
  font-size: 120%;
}